<template>
  <div>
    <audio ref="audioElement"></audio>
    <video ref="defaultVideoElement"></video>
    <video ref="attendeeVideoElement"></video>
  </div>
</template>
<script>
import {
  LogLevel,
  ConsoleLogger,
  DefaultMeetingSession,
  DefaultDeviceController,
  // DefaultActiveSpeakerPolicy,
  MeetingSessionConfiguration
} from 'amazon-chime-sdk-js'

export default {
  name: 'ConferencePage',
  date: () => ({
    meetingSession: null,
    audioVideo: null, // type of AudioVideoFacade object
    joinInfo: null,
    error: null
  }),
  computed: {
    params () {
      return this.$route.params
    }
  },
  methods: {
    handleVideoConference (conferenceParams) {
      this.$logger.log('conferenceParams', conferenceParams)

      const logger = new ConsoleLogger('Logger', LogLevel.OFF)
      const deviceController = new DefaultDeviceController(logger)

      const { meetingResponse, attendeeResponse } = conferenceParams

      const configuration = new MeetingSessionConfiguration(
        meetingResponse,
        attendeeResponse
      )

      configuration.enableWebAudio = true

      this.meetingSession = new DefaultMeetingSession(
        configuration,
        logger,
        deviceController
      )

      this.audioVideo = this.meetingSession.audioVideo

      this.setUpDevicesAndStart()
    },
    setUpDevicesAndStart () {
      // observe audio-video lifecycle
      const observer = {
        videoTileDidUpdate: tileState => {
          this.$logger.log({ tileState })
          const audioElement = this.$refs.audioElement
          const isDefaultVideo = tileState.tileId === 1
          // bind audio output to audio HTML DOM element using ref
          this.audioVideo.bindAudioElement(audioElement)
          this.audioVideo.bindVideoElement(
            tileState.tileId,
            isDefaultVideo ? this.$refs.defaultVideoElement : this.$refs.attendeeVideoElement
          )
        },
        audioVideoDidStart: () => {
          this.$logger.log('Started')
        },
        audioVideoDidStop: sessionStatus => {
          this.$logger.log('Stopped with a session status code: ', sessionStatus.statusCode())
        },
        audioVideoDidStartConnecting: reconnecting => {
          if (reconnecting) {
            this.$logger.log('Attempting to reconnect')
          }
        }
      }
      // set up audio input and out put devices, and bind to DOM audio element
      this.audioVideo
        .listAudioInputDevices()
        .then(audioInputDevices => {
          return this.audioVideo.chooseAudioInputDevice(audioInputDevices[0].deviceId)
        })
        .then(() => {
          return this.audioVideo.listAudioOutputDevices()
        })
        .then(audioOutputDevices => {
          // note: on firefox browsers there audioOutputDevices returns as empty
          // https://github.com/aws/amazon-chime-sdk-js/issues/657#issuecomment-687363939
          return this.audioVideo.chooseAudioOutputDevice(
            audioOutputDevices.length ? audioOutputDevices[0].deviceId : null
          )
        })
        .then(() => {
          const audioElement = this.$refs.audioElement
          // bind audio output to audio HTML DOM element using ref
          return this.audioVideo.bindAudioElement(audioElement)
        })
        .then(() => {
          // register audio-video lifecycle observer
          this.audioVideo.addObserver(observer)

          return this.audioVideo.start()
        })
        .then(() => {
          this.audioVideo
            .listVideoInputDevices()
            .then(videoInputDevices => {
              return this.audioVideo.chooseVideoInputDevice(
                videoInputDevices.length ? videoInputDevices[0].deviceId : null
              )
            })
            .then(() => {
              return this.audioVideo.startLocalVideoTile()
            })
        })
        .then(() => {
          // Unmute
          const unmuted = this.audioVideo.realtimeUnmuteLocalAudio()

          if (unmuted) {
            this.$logger.log('Other attendees can hear your audio')
          } else {
            // See the realtimeSetCanUnmuteLocalAudio use case below.
            this.$logger.log('You cannot unmute yourself')
          }
          const muted = this.audioVideo.realtimeIsLocalAudioMuted()
          if (muted) {
            this.$logger.log('You are muted')
          } else {
            this.$logger.log('Other attendees can hear your audio')
          }
        })
        .catch(err => {
          this.$logger.log('error', err)
          this.error = {
            ...err,
            message: 'There was an error setting up your audio inputs'
          }
        })
    }
  },
  mounted () {
    this.$socket.on('video-conference-params', this.handleVideoConference)

    this.$socket.emit('get-video-conference-url', {
      callID: this.params.id
    })
  }
}
</script>
